<template>
  <div
    class="row justify-center q-gutter-md items-start"
    :class="isSmallScreen ? 'q-pa-xs' : 'q-pa-xl'"
  >
    <q-card
      flat
      bordered
      style="width: 300px;"
      v-for="(val, i) in filteredMenus"
      :key="i"
    >
      <q-img style="height: 170px;" :src="val.img">
        <div class="absolute-bottom text-subtitle2 text-capitalize">
          {{ val.title }}
        </div>
      </q-img>
      <q-separator></q-separator>
      <q-list separator style="width:100%;">
        <q-item
          v-for="(val2, j) in val.links"
          :key="j"
          clickable
          v-ripple
          :to="val2.to"
          v-can="val2.isCan"
        >
          <q-item-section avatar top>
            <q-icon :name="val2.icon" color="blue-4"></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ val2.text }}</q-item-label>
            <q-item-label caption>{{ val2.caption }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  computed: {
    isSmallScreen() {
      return this.$q.screen.lt.md;
    },
    filteredMenus() {
      if (this.isSmallScreen)
        return this.menus.filter((menu) => menu.small_screen);
      else return this.menus;
    },
  },
  data() {
    return {
      jenjang: "",
      alert: false,
      tahun_ajaran: {},
      menus: [
        {
          title: "Penilaian",
          img: "/img/home_1.webp",
          links: [
            {
              small_screen: true,
              to: "/penilaianabsensi",
              text: "Absensi",
              icon: "checklist",
              isCan: ["MUSYRIF"],
              caption:
                "Mengisi data kehadiran Santri pada setiap Kegiatan Rutin Pengasuhan",
            },
            {
              small_screen: true,
              to: "/penilaianriwayatabsensi",
              text: "Riwayat Absensi",
              icon: "switch_account",
              isCan: ["MUSYRIF"],
              caption: "lihat dan edit data kehadiran santri",
            },
            {
              small_screen: true,
              to: "/penilaiantahfidz/-1/-",
              text: "Tahfidz",
              icon: "book",
              isCan: ["TAHFIDZ"],
              caption: "Menginput Setoran Tahfidz para Santri",
            },
            /* {
              to: "/penilaianinputnilai",
              text: "Input Nilai Amal Yaumi",
              icon: "edit",
              caption: "Mengisi penilaian Amalan Yaumi pada santri"
            }, */
            {
              small_screen: true,
              to: "/penilaianinputnilaimobile",
              text: "Input Nilai Amal Yaumi",
              icon: "edit",
              isCan: ["MUSYRIF"],
              caption: "Mengisi penilaian Amalan Yaumi pada santri",
            },
            {
              small_screen: false,
              to: "/penilaianinputcatatan",
              text: "Input Catatan",
              icon: "edit",
              isCan: ["MUSYRIF"],
              caption: "Mengisi Catatan Raport Berdasarkan Nilai Siswa",
            },
            {
              small_screen: true,
              to: "/penilaianinputpembinaan/" + -1,
              text: "Input Pembinaan",
              icon: "escalator_warning",
              isCan: ["MUSYRIF", "MENTOR"],
              caption: "Menginput Hasil Pembinaan Santri",
            },
          ],
        },
        {
          title: "Hasil Penilaian",
          img: "/img/home_2.jpg",
          links: [
            {
              small_screen: true,
              to: "/hasilevaluasiabsensi",
              text: "Evaluasi Kehadiran",
              icon: "directions_walk",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA"],
              caption:
                "Melihat Rekapitulasi Kehadiran Santri pada setiap Kegiatan Pengasuhan",
            },
            {
              small_screen: true,
              to: "/hasilrekappembinaan/" + -1,
              text: "Riwayat Pembinaan",
              icon: "hail",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA"],
              caption: "Melihat Rekapitulasi Pembinaan Peserta Didik",
            },
            {
              small_screen: true,
              to: "/hasilpencapaiantahfidz",
              text: "Pencapaian Tahfidz",
              icon: "hiking",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA", "TAHFIDZ"],
              caption: "Melihat Daftar Pencapaian Tahfidz Peserta Didik",
            },
            {
              small_screen: false,
              to: "/hasil/laporan_akademik",
              text: "Laporan Akademik",
              icon: "school",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA"],
              caption: "Laporan yang diserahkan pihak pengasuhan ke sekolah",
            },
            {
              small_screen: false,
              to: "/hasilraportdigital",
              text: "Raport Digital",
              icon: "menu_book",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA"],
              caption: "Melihat Raport Peserta Didik",
            },
          ],
        },
        {
          title: "Pengaturan",
          img: "/img/home_3.jpg",
          links: [
            {
              small_screen: false,
              to: "/pengaturanaspek",
              text: "Pengaturan Aspek Penilaian",
              icon: "book",
              isCan: ["KEPALA", "WAKA"],
              caption: "Melihat dan Menginput Aspek Penilaian",
            },
            {
              small_screen: true,
              to: "/pengaturansiswabimbingan",
              text: "Pengaturan Anak Bimbingan",
              icon: "group_add",
              isCan: ["MUSYRIF", "TAHFIDZ", "MENTOR"],
              caption: "Memilih Anak Bimbingan Musyrif/ah dan Murabbi",
            },
            {
              small_screen: true,
              to: "/pengaturan/laporan_kerusakan",
              text: "Laporan Kerusakan",
              icon: "build",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA", "TAHFIDZ"],
              caption: "Melaporkan kerusakan sarana dan prasarana",
            },
            {
              small_screen: true,
              to: "/pengaturan/absensi_musyrif",
              text: "Absensi Musyrif",
              icon: "assignment_ind",
              isCan: ["MENTOR", "KEPALA", "WAKA", "TAHFIDZ"],
              caption: "Mengisi kehadiran Musyrif",
            },
            {
              small_screen: true,
              to: "/pengaturandaftarkamar/-1",
              text: "Daftar Kamar",
              icon: "groups",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA", "TAHFIDZ"],
              caption:
                "Melihat Daftar Kamar berikut Musyrif, Mudabbir dan Ketua Kamar nya",
            },
            {
              small_screen: true,
              to: "/pengaturandaftarmusyrif",
              text: "Daftar Musyrif",
              icon: "supervisor_account",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA", "TAHFIDZ"],
              caption: "Melihat Daftar Musyrif dan Anggota Halaqah nya",
            },
            {
              small_screen: true,
              to: "/pengaturandaftarsiswa",
              text: "Daftar Siswa",
              icon: "supervisor_account",
              isCan: ["MUSYRIF", "MENTOR", "KEPALA", "WAKA", "TAHFIDZ"],
              caption: "Melihat Daftar Siswa dan Detailnya",
            },
          ],
        },
      ],
    };
  },
  computed: {
    isSmallScreen() {
      return this.$q.screen.lt.md;
    },
    filteredMenus() {
      if (this.isSmallScreen) {
        let temp = JSON.parse(JSON.stringify(this.menus));
        for (let item of temp) {
          item.links = item.links.filter((menu) => menu.small_screen);
        }
        return temp;
      } else return this.menus;
    },
  },
  mounted() {
    this.jenjang = localStorage.getItem("jenjang");
    this.getTahunAjar();
  },
  methods: {
    ...mapMutations(["clearState"]),
    logout() {
      localStorage.clear();
      this.$q.notify({
        type: "positive",
        message: "proses logout berhasil",
      });
      this.clearState();
      this.$router.push("/login");
    },
    async getTahunAjar() {
      let resp = await this.$http.get(`/tahun_ajaran/${this.jenjang}`);
      if (resp.data.length == 0) {
        this.$q.notify({
          message: `Tidak ada tahun ajaran Berjalan di ${this.jenjang}`,
          color: "warning",
        });
        this.logout();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
